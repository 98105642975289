
import { defineComponent, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "../../../store/enums/StoreEnums";
import { getIllustrationsPath } from "../../../core/helpers/assets";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { ref } from "vue";
import { reactive } from 'vue'
import { EnumsConstant } from "@/store/enums/constants";
import { reinitializeComponents } from "@/core/plugins/keenthemes";
import {
  approveManualOrderRequest,
  getManualOrdersRequest, rejectManualOrderRequest
} from "@/latipay/apis/services/ManualOrdersService";
import { DateTimeUtils } from "@/latipay/utils/DateTimeUtils";
import {
  ApproveManualOrder,
  ManualOrdersResponse,
  RejectManualOrder
} from "@/latipay/apis/models/manualOrder/ManualOrdersResponse";
import { ElMessage } from "element-plus";
import { getAccountsRequest } from "@/latipay/apis/services/AccountsService";
export default defineComponent({
  name: "manualOrders",
  components: {},
  setup() {
    const store = useStore();

    const accountFromId = ref<string | null>(null);
    const accountToId = ref<string | null>(null);
    const inputAmount = ref<number | null>(null);
    const inputRate = ref<number | null>(null);
    const sort = ref<string | null>(null);
    const pageNumber = ref(1);
    const pageSize = ref(20);
    const visible = ref(false);
    const selectedType = ref("All");

    const loadingRef = ref(false);
    const countries = EnumsConstant.CountryCode;


    const formInline = reactive({
      accountFromId: "",
      accountToId: "",
    });

    const {
      manualOrdersRef,
      totalRef,
      pageNumberRef,
      pageSizeRef,
      getManualOrders
    } = getManualOrdersRequest();


    const getData = async () => {
      loadingRef.value = true;

      // await updateQueries();
      await getManualOrders(
              formInline.accountFromId,
              formInline.accountToId,
              selectedType.value === 'All'? null: selectedType.value,
              sort.value,
              pageNumber.value,
              pageSize.value
      );
      reinitializeComponents();

      loadingRef.value = false;

      console.log(manualOrdersRef);
    };

    const onSubmit = () => {
      console.log("submit!");
    };

    const {
      accountsRef,

      getAccounts
    } = getAccountsRequest();
    const getAccountsData = async () => {
      loadingRef.value = true;

      // await updateQueries();
      await getAccounts(
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              1,
              10000
      );
      reinitializeComponents();

      loadingRef.value = false;

      console.log(accountsRef);
    };
    onMounted(async () => {
      // setCurrentPageTitle("Users");
      await getData();
      await getAccountsData();
    });

    const updatePageSize = async (val: number) => {
      console.log(`${val} items per page`);
      pageSize.value = val;
      await getData();
    };
    const updatePageNumber = async (val: number) => {
      console.log(`current page: ${val}`);
      pageNumber.value = val;
      await getData();
    };
    const successAlert = (message => {
      ElMessage({
        showClose: true,
        message: message,
        type: "success"
      });
    });

    const failAlert = (message => {

      ElMessage.error(message)
    });

    const blurAmount =(async (data) => {
      console.log(data);
      if (inputAmount.value) {
        const rate = +(inputAmount.value / data.amountFrom).toFixed(4);
        inputRate.value = rate;
      }
      // reinitializeComponents();

    });

    const blurRate =(async (data) => {
      console.log("!231");
      if (inputRate.value) {
        inputAmount.value = data.amountFrom * inputRate.value;
      }
      // reinitializeComponents();
    });



    const { patchErrorRef: approveErrorRef, approveManualOrder } = approveManualOrderRequest();
    const { patchErrorRef, rejectManualOrder } = rejectManualOrderRequest();


    const popUpConfirm = async (record) => {
      console.log(record.row);
      const approveBody = new ApproveManualOrder();
      const rateString = record.row.conversionRate;
      rateString.rate = inputRate.value;
      if (inputRate.value) {
        approveBody.amountTo = record.row.amountFrom * inputRate.value;
      }else if (inputAmount.value){
        approveBody.amountTo = inputAmount.value;
      }

      approveBody.conversionRate = JSON.stringify(rateString);
      approveBody.remarks = record.row.remarks;
      await approveManualOrderInfo(record.row.id, approveBody as unknown as ManualOrdersResponse);
    };


    const popUpCancel = async (record) => {
      console.log(record);
      console.log(record.row);
      const rejectBody = new RejectManualOrder();

      rejectBody.remarks = record.row.remarks;
      await rejectManualOrderInfo(record.row.id, rejectBody as unknown as ManualOrdersResponse);

    };

    const approveManualOrderInfo = async (id: number, data: ManualOrdersResponse) => {
      loadingRef.value = true;
      await approveManualOrder(id, data);

      if (!approveErrorRef.value) {
        loadingRef.value = false;

        successAlert("Approve order successfully!");

        await getData();
      }else{
        loadingRef.value = false;
        await getData();
        failAlert("Something wrong happened! Please have a check.");
      }

      console.log(approveErrorRef);

    };
    const rejectManualOrderInfo = async (id: number, data: ManualOrdersResponse) => {
      loadingRef.value = true;
      await rejectManualOrder(id, data);

      if (!patchErrorRef.value) {
        loadingRef.value = false;

        successAlert("Reject order successfully!");
        await getData();

      }else{
        loadingRef.value = false;
        await getData();
        failAlert("Something wrong happened! Please have a check.");
      }

      console.log(patchErrorRef);

    };

    const refreshIncreaseList = async () => {

      console.log(selectedType.value);
      selectedType.value = "Increase";
      await getData();
    };
    const refreshDecreaseList = async () => {

      console.log(selectedType.value);
      selectedType.value = "Decrease";
      await getData();
    };
    const refreshTransferList = async () => {

      console.log(selectedType.value);
      selectedType.value = "Transfer";
      await getData();
    };

    const refreshAllList = async () => {

      console.log(selectedType.value);
      selectedType.value = "";
      await getData();
    };



    return {
      getIllustrationsPath,
      formInline,
      onSubmit,
      countries,
      accountsRef,
      refreshIncreaseList,
      refreshDecreaseList,
      refreshTransferList,
      getData,
      updatePageSize,
      updatePageNumber,
      manualOrdersRef,
      totalRef,
      refreshAllList,
      DateTimeUtils,
      pageNumberRef,
      pageSizeRef,
      accountFromId,
      accountToId,
      popUpConfirm,
      loadingRef,
      popUpCancel,
      inputAmount,
      inputRate,
      blurAmount,
      blurRate,
      visible,
      selectedType,



    };
  },
  methods: {
    async resetFormFields() {

      (this.$refs["searchForm"] as any).resetFields();

      await this.getData();

    },
    async onSubmitSearchForm() {
      await this.getData();
    }

  }
});
